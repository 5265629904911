import { isEmpty } from 'lodash';
import { instrumentService } from '../../services/instruments';
import { Entity } from '../abstractions';
import { InstrumentType } from '../enumerations';
import { Exchange } from '../enumerations/exchange';
import { MarketHours } from '../enumerations/market-hours';
export class Instrument extends Entity {
    symbol;
    name;
    type;
    exchange;
    bigPointValue;
    static create(symbol, name, type, exchange, bigPointValue) {
        return new Instrument(symbol, name, type, exchange, bigPointValue);
    }
    constructor(symbol, name, type, exchange, bigPointValue) {
        super(symbol);
        this.symbol = symbol;
        this.name = name;
        this.type = type;
        this.exchange = exchange;
        this.bigPointValue = bigPointValue;
    }
    get hasFuture() {
        return !isEmpty(instrumentService.getFutureSymbols(this.symbol));
    }
    get underlying() {
        return instrumentService.getUnderlying(this.symbol);
    }
    get sessions() {
        let marketHours = this.determinTWMarketHours() ||
            this.determinCbtMarketHours() ||
            this.determinIusMarketHours() ||
            this.determinSgxMarketHours();
        if (this.exchange.hours.length === 0) {
            console.log(`PLEASE HARD CODE HOURS for ${this.symbol.value} ${this.exchange.name}}`);
        }
        if (!marketHours) {
            marketHours = this.exchange.hours[0];
        }
        return marketHours;
    }
    determinTWMarketHours() {
        if (RegExp(/TF-\d/i).exec(this.symbol.value)) {
            return MarketHours.TFE_AM;
        }
        if (this.symbol.value.startsWith('CDF-') || this.symbol.value.startsWith('QFF-')) {
            return MarketHours.TFE_SF_AMPM;
        }
        if (RegExp(/T[EX]-\d/i).exec(this.symbol.value) ||
            this.type.equals(InstrumentType.TaiwanOptions)) {
            return MarketHours.TFE_Standard;
        }
        if (this.symbol.value.includes('AM-')) {
            return MarketHours.TFE_AM;
        }
        if (this.symbol.value.includes('PM-')) {
            return MarketHours.TFE_PM;
        }
        return undefined;
    }
    determinCbtMarketHours() {
        if (this.exchange.equals(Exchange.CBT)) {
            if (this.symbol.value === 'BO-1' ||
                this.symbol.value === 'C-1' ||
                this.symbol.value === 'S-1' ||
                this.symbol.value === 'BO-1') {
                return MarketHours.CBT_Agricultural;
            }
            else {
                return MarketHours.CBT;
            }
        }
        return undefined;
    }
    determinIusMarketHours() {
        if (this.exchange.equals(Exchange.IUS)) {
            if (this.symbol.value === 'CC-1' ||
                this.symbol.value === 'KC-1' ||
                this.symbol.value === 'SB-1') {
                return MarketHours.IUS_Soft;
            }
            else {
                return MarketHours.IUS;
            }
        }
        return undefined;
    }
    determinSgxMarketHours() {
        if (this.exchange.equals(Exchange.SGX)) {
            if (this.symbol.value === 'CN-1' || this.symbol.value === 'FCH-1') {
                return MarketHours.SGX_CN;
            }
            else if (this.symbol.value === 'TWN-1') {
                return MarketHours.SGX_TWN;
            }
            else if (this.symbol.value === 'NK-1') {
                return MarketHours.SGX_NK;
            }
        }
        return undefined;
    }
}
