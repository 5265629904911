import { isOpbsSymbol, optionTranslator } from '~/utils/optionsTranslator';
import { InstrumentType } from '../../domain/enumerations';
import { Exchange } from '../../domain/enumerations/exchange';
import { isEmpty } from 'lodash';
export class InstrumentRepository {
    static instruments = new Map();
    static symbolOfStockToNameMap = new Map();
    static symbolToNameMap = new Map();
    static symbolOfStockFutures = new Map();
    static symbolOfUnderlying = new Map();
    static symbolToExchangeMap = new Map();
    static symbolToTypeMap = new Map();
    static symbolToBigPointValue = new Map();
    static symbolToSessionsMap = new Map();
    static symbolToIndustry = {
        categories: {},
        industries: {},
        symbols: {},
    };
    static find(symbol) {
        return this.instruments.get(symbol.value);
    }
    static add(instrument) {
        this.instruments.set(instrument.symbol.value, instrument);
    }
    static findName(symbol) {
        let name = this.symbolToNameMap.get(symbol.value);
        if (!name)
            name = this.symbolOfStockToNameMap.get(symbol.value);
        if (!name)
            name = optionTranslator(symbol.value);
        if (!name)
            name = symbol.value;
        return name;
    }
    static findExchange(symbol) {
        if (!isEmpty(InstrumentRepository.symbolOfStockToNameMap.get(symbol.value))) {
            return Exchange.TWSE;
        }
        // const exchangeFromTvAPI = InstrumentCache.symbolToExchangeMap.get(symbol.value)
        // if (!isEmpty(exchangeFromTvAPI)) {
        //   return Exchange.fromName(exchangeFromTvAPI)
        // }
        let exchange = InstrumentRepository.symbolToExchangeMap.get(symbol.value);
        if (RegExp(/TX[O\d]\d{5}[A-X]\d/).exec(symbol.value)) {
            exchange = Exchange.TFE;
        }
        return exchange ?? Exchange.Unknown;
    }
    static findInstrumentType(symbol) {
        if (!isEmpty(InstrumentRepository.symbolOfStockToNameMap.get(symbol.value))) {
            return InstrumentType.TaiwanStocks;
        }
        const symbolToType = InstrumentRepository.symbolToTypeMap.get(symbol.value);
        if (!isEmpty(symbolToType)) {
            return InstrumentType.fromName(symbolToType.name);
        }
        if (isOpbsSymbol(symbol.value)) {
            return InstrumentType.TaiwanOptions;
        }
        return InstrumentType.Unknown;
    }
    static findBigPointValue(symbol) {
        const bigPointValue = InstrumentRepository.symbolToBigPointValue.get(symbol.value);
        if (!bigPointValue) {
            if (symbol.value.includes('MTX')) {
                return 50;
            }
            if (symbol.value.includes('TX')) {
                return 200;
            }
            if (symbol.value.match(/[\d]{4}/)) {
                return 1000;
            }
        }
        return bigPointValue || 0;
    }
}
